import { css, styled } from "styled-components";
import { IconContainerProps } from ".";

export const BellyWellyLogo = ({ width = 114, height = 25 }) => {
  return (
    <IconContainer width={width} height={height}>
      <svg
        width="114"
        height="25"
        viewBox="0 0 114 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M105.188 24.4351V20.9078L107.138 20.9334C107.48 20.9334 107.809 20.8778 108.125 20.7667C108.442 20.6555 108.733 20.5016 108.998 20.3049C109.271 20.1082 109.511 19.8731 109.716 19.5994C109.921 19.3343 110.088 19.0436 110.216 18.7272C109.831 18.8897 109.438 19.0522 109.036 19.2146C108.643 19.3771 108.245 19.4583 107.843 19.4583C106.997 19.4583 106.201 19.3087 105.457 19.0094C104.714 18.7101 104.059 18.2868 103.495 17.7395C102.939 17.1837 102.499 16.5125 102.174 15.7258C101.857 14.9305 101.699 14.0412 101.699 13.0578V5.46436H105.188V13.0578C105.188 13.5452 105.257 13.9728 105.393 14.3405C105.539 14.6996 105.731 15.0032 105.971 15.2512C106.21 15.4906 106.488 15.6702 106.804 15.7899C107.129 15.9096 107.476 15.9695 107.843 15.9695C108.202 15.9695 108.54 15.8882 108.857 15.7258C109.181 15.5547 109.464 15.3324 109.703 15.0588C109.943 14.7851 110.131 14.4773 110.267 14.1352C110.404 13.7846 110.473 13.4255 110.473 13.0578V5.46436H114V17.4702C113.992 18.4365 113.803 19.3429 113.436 20.1895C113.068 21.036 112.563 21.7757 111.922 22.4085C111.289 23.0413 110.55 23.5415 109.703 23.9092C108.857 24.2769 107.95 24.4608 106.984 24.4608L105.188 24.4351Z"
          fill="#5C5856"
        />
        <path d="M99.4605 19.2016H95.9331V0H99.4605V19.2016Z" fill="#5C5856" />
        <path d="M93.5151 19.2016H89.9878V0H93.5151V19.2016Z" fill="#5C5856" />
        <path
          d="M81.9131 15.8412C82.0499 15.884 82.1867 15.9139 82.3235 15.931C82.4604 15.9395 82.5972 15.9438 82.734 15.9438C83.076 15.9438 83.4053 15.8968 83.7217 15.8027C84.0381 15.7087 84.3331 15.5761 84.6067 15.4051C84.8889 15.2255 85.1369 15.0117 85.3507 14.7638C85.573 14.5072 85.7526 14.225 85.8894 13.9172L88.4547 16.4954C88.1298 16.9571 87.7535 17.3719 87.326 17.7396C86.907 18.1073 86.4495 18.4194 85.9535 18.6759C85.4661 18.9325 84.9488 19.1249 84.4015 19.2531C83.8628 19.3899 83.3069 19.4584 82.734 19.4584C81.7677 19.4584 80.857 19.2788 80.0019 18.9196C79.1553 18.5605 78.4114 18.0602 77.7701 17.4189C77.1373 16.7776 76.637 16.0165 76.2693 15.1357C75.9016 14.2464 75.7178 13.2716 75.7178 12.2112C75.7178 11.1252 75.9016 10.1333 76.2693 9.23544C76.637 8.33756 77.1373 7.57223 77.7701 6.93945C78.4114 6.30666 79.1553 5.81497 80.0019 5.46437C80.857 5.11378 81.7677 4.93848 82.734 4.93848C83.3069 4.93848 83.867 5.00689 84.4143 5.1437C84.9616 5.28052 85.4789 5.4772 85.9663 5.73373C86.4623 5.99027 86.9241 6.30666 87.3516 6.68291C87.7792 7.05061 88.1554 7.46535 88.4804 7.92711L81.9131 15.8412ZM83.7088 8.61975C83.5464 8.55989 83.3839 8.52141 83.2214 8.50431C83.0675 8.48721 82.905 8.47866 82.734 8.47866C82.2551 8.47866 81.8019 8.56845 81.3744 8.74802C80.9554 8.91904 80.5877 9.16703 80.2713 9.49197C79.9634 9.81692 79.7197 10.2103 79.5401 10.672C79.3606 11.1252 79.2708 11.6383 79.2708 12.2112C79.2708 12.3395 79.2751 12.4849 79.2836 12.6473C79.3007 12.8098 79.3221 12.9766 79.3477 13.1476C79.3819 13.3101 79.4204 13.4683 79.4632 13.6222C79.5059 13.7761 79.5615 13.9129 79.6299 14.0326L83.7088 8.61975Z"
          fill="#5C5856"
        />
        <path
          d="M74.1722 14.2376C74.1722 14.9559 74.0354 15.6357 73.7617 16.277C73.4881 16.9098 73.1118 17.4614 72.633 17.9317C72.1627 18.402 71.6068 18.774 70.9655 19.0476C70.3327 19.3213 69.6529 19.4581 68.926 19.4581C68.2761 19.4581 67.6519 19.3469 67.0533 19.1246C66.4548 18.8937 65.9118 18.5602 65.4243 18.1241C64.9455 18.5602 64.4067 18.8937 63.8082 19.1246C63.2181 19.3469 62.5939 19.4581 61.9355 19.4581C61.2086 19.4581 60.5288 19.3213 59.896 19.0476C59.2632 18.774 58.7074 18.402 58.2285 17.9317C57.7582 17.4614 57.3862 16.9098 57.1126 16.277C56.839 15.6357 56.7021 14.9559 56.7021 14.2376V5.48975H60.2038V14.2376C60.2038 14.477 60.2466 14.7036 60.3321 14.9174C60.4262 15.1226 60.5502 15.3065 60.7041 15.469C60.8666 15.6229 61.0504 15.7469 61.2556 15.8409C61.4694 15.9264 61.696 15.9692 61.9355 15.9692C62.1749 15.9692 62.4015 15.9264 62.6153 15.8409C62.8291 15.7469 63.0172 15.6229 63.1797 15.469C63.3421 15.3065 63.4661 15.1226 63.5516 14.9174C63.6457 14.7036 63.6927 14.477 63.6927 14.2376V5.48975H67.1816V14.2376C67.1816 14.477 67.2286 14.7036 67.3227 14.9174C67.4168 15.1226 67.5407 15.3065 67.6947 15.469C67.8571 15.6229 68.041 15.7469 68.2462 15.8409C68.46 15.9264 68.6866 15.9692 68.926 15.9692C69.1655 15.9692 69.3921 15.9264 69.6059 15.8409C69.8196 15.7469 70.0035 15.6229 70.1574 15.469C70.3199 15.3065 70.4481 15.1226 70.5422 14.9174C70.6363 14.7036 70.6833 14.477 70.6833 14.2376V5.48975H74.1722V14.2376Z"
          fill="#5C5856"
        />
        <path
          d="M45.6002 24.4351V20.9078L47.5499 20.9334C47.8919 20.9334 48.2211 20.8778 48.5375 20.7667C48.8539 20.6555 49.1447 20.5016 49.4097 20.3049C49.6834 20.1082 49.9228 19.8731 50.128 19.5994C50.3333 19.3343 50.5 19.0436 50.6283 18.7272C50.2435 18.8897 49.8501 19.0522 49.4482 19.2146C49.0549 19.3771 48.6572 19.4583 48.2553 19.4583C47.4088 19.4583 46.6135 19.3087 45.8696 19.0094C45.1256 18.7101 44.4714 18.2868 43.9071 17.7395C43.3512 17.1837 42.9109 16.5125 42.5859 15.7258C42.2695 14.9305 42.1113 14.0412 42.1113 13.0578V5.46436H45.6002V13.0578C45.6002 13.5452 45.6686 13.9728 45.8054 14.3405C45.9508 14.6996 46.1432 15.0032 46.3826 15.2512C46.6221 15.4906 46.9 15.6702 47.2164 15.7899C47.5413 15.9096 47.8876 15.9695 48.2553 15.9695C48.6145 15.9695 48.9523 15.8882 49.2687 15.7258C49.5936 15.5547 49.8758 15.3324 50.1152 15.0588C50.3546 14.7851 50.5428 14.4773 50.6796 14.1352C50.8164 13.7846 50.8848 13.4255 50.8848 13.0578V5.46436H54.4122V17.4702C54.4036 18.4365 54.2155 19.3429 53.8478 20.1895C53.4801 21.036 52.9756 21.7757 52.3342 22.4085C51.7015 23.0413 50.9618 23.5415 50.1152 23.9092C49.2687 24.2769 48.3622 24.4608 47.3959 24.4608L45.6002 24.4351Z"
          fill="#5C5856"
        />
        <path d="M39.8731 19.2016H36.3457V0H39.8731V19.2016Z" fill="#5C5856" />
        <path d="M33.9273 19.2016H30.3999V0H33.9273V19.2016Z" fill="#5C5856" />
        <path
          d="M22.3252 15.8412C22.462 15.884 22.5988 15.9139 22.7357 15.931C22.8725 15.9395 23.0093 15.9438 23.1461 15.9438C23.4882 15.9438 23.8174 15.8968 24.1338 15.8027C24.4502 15.7087 24.7452 15.5761 25.0188 15.4051C25.301 15.2255 25.549 15.0117 25.7628 14.7638C25.9851 14.5072 26.1647 14.225 26.3015 13.9172L28.8668 16.4954C28.5419 16.9571 28.1656 17.3719 27.7381 17.7396C27.3191 18.1073 26.8616 18.4194 26.3656 18.6759C25.8782 18.9325 25.3609 19.1249 24.8136 19.2531C24.2749 19.3899 23.719 19.4584 23.1461 19.4584C22.1798 19.4584 21.2691 19.2788 20.414 18.9196C19.5675 18.5605 18.8235 18.0602 18.1822 17.4189C17.5494 16.7776 17.0491 16.0165 16.6814 15.1357C16.3137 14.2464 16.1299 13.2716 16.1299 12.2112C16.1299 11.1252 16.3137 10.1333 16.6814 9.23544C17.0491 8.33756 17.5494 7.57223 18.1822 6.93945C18.8235 6.30666 19.5675 5.81497 20.414 5.46437C21.2691 5.11378 22.1798 4.93848 23.1461 4.93848C23.719 4.93848 24.2791 5.00689 24.8264 5.1437C25.3737 5.28052 25.891 5.4772 26.3785 5.73373C26.8744 5.99027 27.3362 6.30666 27.7637 6.68291C28.1913 7.05061 28.5675 7.46535 28.8925 7.92711L22.3252 15.8412ZM24.1209 8.61975C23.9585 8.55989 23.796 8.52141 23.6335 8.50431C23.4796 8.48721 23.3171 8.47866 23.1461 8.47866C22.6672 8.47866 22.214 8.56845 21.7865 8.74802C21.3675 8.91904 20.9998 9.16703 20.6834 9.49197C20.3755 9.81692 20.1318 10.2103 19.9523 10.672C19.7727 11.1252 19.6829 11.6383 19.6829 12.2112C19.6829 12.3395 19.6872 12.4849 19.6957 12.6473C19.7128 12.8098 19.7342 12.9766 19.7599 13.1476C19.7941 13.3101 19.8325 13.4683 19.8753 13.6222C19.918 13.7761 19.9736 13.9129 20.042 14.0326L24.1209 8.61975Z"
          fill="#5C5856"
        />
        <path
          d="M14.7123 13.6862C14.7123 14.4472 14.5669 15.1612 14.2762 15.8282C13.9854 16.4952 13.5878 17.081 13.0833 17.5855C12.5873 18.0815 12.0058 18.4748 11.3388 18.7655C10.6719 19.0563 9.95783 19.2017 9.19678 19.2017H0V0.808105H9.19678C9.95783 0.808105 10.6719 0.953475 11.3388 1.24421C12.0058 1.53495 12.5873 1.93258 13.0833 2.4371C13.5878 2.93307 13.9854 3.51455 14.2762 4.18154C14.5669 4.84853 14.7123 5.56255 14.7123 6.32361C14.7123 6.66565 14.6652 7.01625 14.5712 7.3754C14.4771 7.73455 14.3446 8.08087 14.1736 8.41436C14.0025 8.74786 13.7973 9.05143 13.5579 9.32506C13.3184 9.5987 13.0534 9.82531 12.7626 10.0049C13.0619 10.1674 13.3313 10.3897 13.5707 10.6719C13.8101 10.9455 14.0154 11.2491 14.1864 11.5826C14.3574 11.9161 14.4857 12.2667 14.5712 12.6344C14.6652 12.9935 14.7123 13.3441 14.7123 13.6862ZM3.68128 15.5204H9.19678C9.45331 15.5204 9.69274 15.4733 9.91507 15.3793C10.1374 15.2852 10.3298 15.157 10.4923 14.9945C10.6633 14.8235 10.7958 14.6268 10.8899 14.4045C10.984 14.1821 11.031 13.9427 11.031 13.6862C11.031 13.4296 10.984 13.1902 10.8899 12.9679C10.7958 12.7455 10.6633 12.5531 10.4923 12.3907C10.3298 12.2196 10.1374 12.0871 9.91507 11.993C9.69274 11.899 9.45331 11.8519 9.19678 11.8519H3.68128V15.5204ZM3.68128 8.17066H9.19678C9.45331 8.17066 9.69274 8.12362 9.91507 8.02956C10.1374 7.9355 10.3298 7.80723 10.4923 7.64476C10.6633 7.47374 10.7958 7.27706 10.8899 7.05473C10.984 6.82385 11.031 6.58014 11.031 6.32361C11.031 6.06707 10.984 5.82764 10.8899 5.60531C10.7958 5.38298 10.6633 5.19058 10.4923 5.0281C10.3298 4.85708 10.1374 4.72454 9.91507 4.63048C9.69274 4.53641 9.45331 4.48938 9.19678 4.48938H3.68128V8.17066Z"
          fill="#5C5856"
        />
      </svg>
    </IconContainer>
  );
};

const IconContainer = styled.div<IconContainerProps>`
  ${({ width, height }) => css`
    width: ${width}px;
    height: ${height}px;
  `}
`;
